import { initializeApp } from "firebase/app";
import {
  getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut,
  sendPasswordResetEmail, sendSignInLinkToEmail, updatePassword, updateProfile, createUserWithEmailAndPassword
} from "firebase/auth";
import { getFirestore, ref } from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyCVcgc3Q349SdcIp83y8bWKm0O1cEg_P5A",
  databaseURL: "https://mynotif-26833.firebaseio.com",
  storageBucket: "mynotif-26833.appspot.com",
  authDomain: "mynotif-26833.firebaseapp.com",
  messagingSenderId: "479126697298",
  projectId: "mynotif-26833",
};

export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const onAuthStateChanged2 = (func) => onAuthStateChanged(auth, func);
// export const googleProvider = new auth.GoogleAuthProvider();

export const db = getFirestore(app);

export const doCreateUserWithEmailAndPassword = (email, password) =>
  createUserWithEmailAndPassword(auth, email, password);

export const doSignInWithEmailAndPassword = (email, password, error) =>
  signInWithEmailAndPassword(auth, email, password).catch(function (err) { if (error) error(err); });

export const doSignOut = () => signOut(auth);

export const doPasswordReset = (email) => sendPasswordResetEmail(auth, email);

export const doPasswordUpdate = (password) =>
  updatePassword(auth.currentUser, password);

export const doUdateProfile = (displayName, photoURL) =>
  updateProfile(auth.currentUser, { displayName: displayName, photoURL: photoURL, });

export const doSendSignInLinkToEmail = (email) => sendSignInLinkToEmail(auth, email, {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be whitelisted in the Firebase Console.
  url: "https://notif.enaj.org" + /* ROUTES.AUTH_EMAIL_LINK + */"?email=" + email,
  // This must be true.
  handleCodeInApp: true,
});

export const connected = () => db.ref(".info/connected");

// *** User API ***

export const user = (uid) => db.ref(`users/${uid}`);

export const users = () => db.ref("users");

// *** Events API ***
export const events = () => db.ref("events");
