<script lang="ts">
  import { Router, Route } from "svelte-navigator";
  import Login from "../Login/Login.svelte";
  import PrivateRoute from "../Login/PrivateRoute.svelte";
  import Tts from "../Tts/Tts.svelte";
  import { user } from "../stores";
  import { navigate } from "svelte-navigator";
  import Home from "../Home/Home.svelte";
  import { onAuthStateChanged2, doSignOut } from "../firebase";
  import BootstrapTemplate from "./BootstrapTemplate.svelte";
  import Account from "../Account/Account.svelte";
  import { defaultRoute } from "../constants";
  import Navbar from "./Navbar.svelte";

  onAuthStateChanged2(function (firebase_user) {
    if (firebase_user) {
      $user = firebase_user;
      //console.log(firebase_user);
      navigate(defaultRoute, { replace: true });
    } else {
      $user = null;
      //console.log(firebase_user);
      navigate("/login", { replace: true });
    }
  });
</script>

<Router>
  {#if $user}
    <Navbar user={$user} />
  {/if}
  <main>
    <Route path="login">
      <Login />
    </Route>

    <PrivateRoute path="/">
      <Home />
    </PrivateRoute>

    <PrivateRoute path="/tts" let:location>
      <Tts />
    </PrivateRoute>

    <PrivateRoute path="/account" let:location>
      <Account user={$user} />
    </PrivateRoute>

    <PrivateRoute path="/profile" let:location>
      <h3>Profile: Welcome {$user.displayName}</h3>
      <button on:click={doSignOut}>Logout</button>
    </PrivateRoute>
  </main>
</Router>

<style>
  /* @import url(https://fonts.googleapis.com/icon?family=Material+Icons); */
</style>
