import { mdiAccount, mdiBullhorn } from "@mdi/js";
export const menuNavbar = [
  {
    to: "/",
    label: "Home",
    enable: true,
    icon: mdiAccount,
  },
  {
    to: "/account",
    label: "Account",
    enable: true,
    icon: mdiAccount,
  },
  {
    to: "/tts",
    label: "Tts",
    enable: true,
    icon: mdiBullhorn,
  },
  {
    to: "/tts",
    label: "Disabled",
    enable: false,
    icon: mdiAccount,
  },
];
