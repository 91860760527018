import "svelte"; // fix maybe it could be deleted on future versions, i'm getting the following error:
/* (!) Plugin typescript: @rollup/plugin-typescript TS2307: Cannot find module './App.svelte' or its corresponding type declarations. */
import App from "./App/App.svelte";
import "bootstrap/dist/css/bootstrap.min.css";

const app = new App({
  target: document.body,
  // props: {
  // mode: 'prod'
  // }
});

export default app;
