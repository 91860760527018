
type ParseResult = {
    text: string;
    name: string;
};

type StandardResult = {
    data: ParseResult[] | null;
    error: string | undefined
}

export const parseTtsText = function (text: string): StandardResult {
  const result: StandardResult = { data: null, error: "Unknown error.", };
  const data = [];
  text.split("\n").forEach(line => {
    const values = line.split("|", 2);
    if (!values) {
      result.error = "Invalid batch format. Ex: name|text";
      return;
    } else if (values && values.length !== 2) {
      result.error = "Invalid batch format(2). Ex: name|text";
      return;
    }
    const n = formatFilename(values[0]); // name
    const t = values[1]; // text
    const pr = { text: t, name: n, } as ParseResult;
    data.push(pr);
  });
  if (data.length > 0) {
    result.data = data;
    result.error = undefined;
  }
  return result;
};

const formatFilename = function (name: string) : string {
  return name.replace(/\.[A-Za-z0-9]{3}$/, "") + ".mp3";
};

export const downloadAllTtsFiles = function (files) {
  console.log(files);
  function download_next (i) {
    if (i >= files.length) {
      return;
    }
    const a = document.createElement("a");
    a.setAttribute("href", "data:application/octet-stream," + encodeURIComponent(files[i].url));
    // a.href = files[i].url;
    a.target = "_parent";
    // Use a.download if available, it prevents plugins from opening.
    if ("download" in a) {
      a.download = files[i].name;
    }
    // Add a to the doc for click to work.
    (document.body || document.documentElement).appendChild(a);
    if (a.click) {
      a.click(); // The click method is supported by most browsers.
    } else {
      // $(a).click(); // Backup using jquery
    }
    // Delete the temporary link.
    a.parentNode.removeChild(a);
    // Download the next file with a small timeout. The timeout is necessary
    // for IE, which will otherwise only download the first file.
    setTimeout(function () {
      download_next(i + 1);
    }, 500);
  }
  // Initiate the first download.
  download_next(0);
};

export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
