<script lang="ts">
  import type firebase from "firebase";
  import { doSignOut } from "../firebase";
  import ChangePassword from "./ChangePassword.svelte";

  export let user: firebase.User;
</script>

<h3>Account: Welcome {user.displayName}</h3>
<button on:click={doSignOut}>Logout</button>
<br />
<br />
<br />
<ChangePassword {user} />
