import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { Polly, PollyClient } from "@aws-sdk/client-polly";
import { getSynthesizeSpeechUrl } from "@aws-sdk/polly-request-presigner";

const credentials = fromCognitoIdentityPool({
  client: new CognitoIdentityClient({ region: "us-east-1", }),
  identityPoolId: "us-east-1:63c4fc79-8ebf-4b03-bc84-ff249dd59641",
});

// Create the Polly service client, assigning your credentials
const client = new Polly({
  region: "us-east-1",
  credentials: credentials,
});

// Set the parameters
const speechParams = {
  OutputFormat: "mp3", // For example, 'mp3'
  SampleRate: "16000", // For example, '16000
  Text: "", // The 'speakText' function supplies this value
  TextType: "text", // For example, "text"
  VoiceId: "Matthew", // For example, "Matthew"
};

export const speakText = (text, voiceId) => {
  const params = Object.assign({}, speechParams);
  params.Text = text;
  params.VoiceId = voiceId;
  const c = new PollyClient({ region: "us-east-1", credentials: credentials, });
  return getSynthesizeSpeechUrl({
    client: c, params: params,
  });
};

export const getVoices = () => {
  const params = {
  };
  return client.describeVoices(params);
  /*
    data = {
     Voices: [
        {
       Gender: "Female",
       Id: "Emma",
       LanguageCode: "en-GB",
       LanguageName: "British English",
       Name: "Emma"
      },
        {
       Gender: "Male",
       Id: "Brian",
       LanguageCode: "en-GB",
       LanguageName: "British English",
       Name: "Brian"
      },
        {
       Gender: "Female",
       Id: "Amy",
       LanguageCode: "en-GB",
       LanguageName: "British English",
       Name: "Amy"
      }
     ]
    }
    */
};
export const compareVoiceByNameForSort = (first, second) => {
  if (first.Name < second.Name) {
    return -1;
  }
  if (first.Name > second.Name) {
    return 1;
  }
  return 0;
};

export const groupByLanguage = (voices) => {
  const map = new Map();
  map.set("es", []);
  map.set("en", []);
  voices.forEach((e) => {
    const l = e.LanguageCode.split("-")[0];
    if (!map.has(l)) map.set(l, []);
    map.get(l).push(e);
  });
  return map;
};
