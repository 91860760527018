<script lang="ts">
  import type firebase from "firebase";

  export let user: firebase.User;

  let password = "";
  let repeatPassword = "";

  const onClick = () => {
    if (password != repeatPassword) {
      alert("passwords does not match");
    }
  };
</script>

<form>
  <div class="mb-3">
    <label for="emailInput" class="form-label">email</label>
    <input
      type="email"
      class="form-control"
      id="emailInput"
      value={user.email}
    />
  </div>
  <div class="mb-3">
    <label for="passwordInput" class="form-label">New Password</label>
    <input
      type="password"
      class="form-control"
      id="passwordInput"
      bind:value={password}
    />
  </div>
  <div class="mb-3">
    <label for="repeatPasswordInput" class="form-label">Repeat Password</label
    ><input
      type="password"
      class="form-control"
      id="repeatPasswordInput"
      bind:value={repeatPassword}
    />
  </div>
  <div class="mb-3">
    <button class="btn btn-primary" type="submit" on:click={onClick}
      >ChangePassword</button
    >
  </div>
</form>
