<script>
    import { doSignInWithEmailAndPassword } from "../firebase";

    let username = "";
    let password = "";
    let error_message = false;
    function handleClick() {
        error_message = false;
        doSignInWithEmailAndPassword(username, password, function (error) {
            console.log(error.message);
            error_message = error.message;
        });
    }
</script>

<form class="form-signin">
    <img
        class="mb-4"
        src="/docs/4.3/assets/brand/bootstrap-solid.svg"
        alt=""
        width="72"
        height="72"
    />
    <h1 class="h3 mb-3 font-weight-normal">Please sign in</h1>
    <label for="inputEmail" class="sr-only">Email address</label>
    <input
        type="email"
        id="inputEmail"
        class="form-control"
        placeholder="Email address"
        required
        bind:value={username}
    />
    <label for="inputPassword" class="sr-only">Password</label>
    <input
        type="password"
        id="inputPassword"
        class="form-control"
        placeholder="Password"
        required
        bind:value={password}
    />
    <!-- <div class="checkbox mb-3">
        <label>
            <input type="checkbox" value="remember-me" /> Remember me
        </label>
    </div> -->
    <button
        class="btn btn-lg btn-primary btn-block"
        type="submit"
        on:click|preventDefault={handleClick}>Sign in</button
    >
    {#if error_message}
        <div class="alert alert-danger" role="alert">
            {error_message}
        </div>
    {/if}
    <p class="mt-5 mb-3 text-muted">&copy; 2017-2019</p>
</form>

<style>
    .form-signin {
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: auto;
    }
    /* .form-signin .checkbox {
        font-weight: 400;
    } */
    .form-signin .form-control {
        position: relative;
        box-sizing: border-box;
        height: auto;
        padding: 10px;
        font-size: 16px;
    }
    .form-signin .form-control:focus {
        z-index: 2;
    }
    .form-signin input[type="email"] {
        margin-bottom: -1px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .form-signin input[type="password"] {
        margin-bottom: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
</style>
