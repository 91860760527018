<script>
  import {
    speakText,
    getVoices,
    compareVoiceByNameForSort,
    groupByLanguage,
  } from "./polly";
  import { parseTtsText, downloadAllTtsFiles } from "./ttsUtils";

  let mode = "single";
  let textValue = "";
  let selectedVoice = localStorage.defaultVoice
    ? localStorage.defaultVoice
    : "unselected";
  let voicesMap = new Map();
  let ttsArray;

  let error = null;

  getVoices()
    .then((data) => {
      voicesMap = groupByLanguage(data.Voices.sort(compareVoiceByNameForSort));
    })
    .catch((err) => {
      console.log(err);
      error = err;
    });

  function handleClick() {
    if (textValue === "") {
      error = "Please enter some text.";
    } else if (!selectedVoice || selectedVoice === "unselected") {
      error = "You must select a voice.";
    } else if (mode === "single") {
      speakText(textValue, selectedVoice)
        .then((data) => {
          error = null;
          ttsArray = [{ url: data, text: textValue, name: "tts.mp3" }];
        })
        .catch((err) => {
          console.log(err);
          ttsArray = null;
          error = "Error: " + err;
        });
    } else if (mode === "batch") {
      const result = parseTtsText(textValue);
      if (!result) {
        error = "Unknown parse error.";
        return;
      } else if (result.error) {
        error = result.error;
        return;
      } else if (!result.data) {
        error = "Unknown parse error.";
        return;
      }
      const requests = [];
      result.data.forEach((e) => {
        const promise = speakText(e.text, selectedVoice).then((data) => {
          return { url: data, text: e.text, name: e.name };
        });
        requests.push(promise);
      });

      Promise.all(requests)
        .then((values) => {
          //console.log(values);
          error = null;
          ttsArray = values;
        })
        .catch((err) => {
          console.log(err);
          ttsArray = null;
          error = "Error: " + err;
        });
    } else {
      error = "Unknown error.";
    }
  }

  const saveDefaultVoice = (async) => {
    localStorage.defaultVoice = selectedVoice;
  };
</script>

<h1 class="my-2">TTS</h1>

{#if error}
  <div class="alert alert-danger" role="alert">
    {error}
  </div>
{/if}

<form>
  <div class="form-check my-2">
    <input
      class="form-check-input"
      type="radio"
      bind:group={mode}
      name="mode"
      id="singleRadio"
      value="single"
    />
    <label class="form-check-label" for="flexRadioDefault1">Single</label>
  </div>
  <div class="form-check my-2">
    <input
      class="form-check-input"
      type="radio"
      bind:group={mode}
      name="mode"
      id="batchRadio"
      value="batch"
    />
    <label class="form-check-label" for="flexRadioDefault2">Batch</label>
  </div>

  <div class="my-2">
    <label for="textTextArea" class="form-label">Text</label>
    <textarea
      class="form-control"
      id="textTextArea"
      bind:value={textValue}
      rows={mode === "single" ? 3 : 10}
      placeholder={mode === "single"
        ? "Enter your text here"
        : "format: name|text"}
    />
    <div id="textHelp" class="form-text">
      {mode === "single" ? "" : "format: name|text"}
    </div>
  </div>

  <div class="form-group my-2">
    <label for="selectedVoiceSelect">Voice:</label>
    <select
      class="form-control"
      id="selectedVoiceSelect"
      bind:value={selectedVoice}
      on:blur={saveDefaultVoice}
    >
      <option value="unselected">[Select a voice]</option>
      {#each [...voicesMap] as [key, value]}
        <optgroup label={key}>
          {#each value as voice (voice.Id)}
            <option value={voice.Id}>
              {voice.Name} ({voice.Gender}) - {voice.LanguageName}
            </option>
          {/each}
        </optgroup>
      {/each}
    </select>
  </div>
  <button
    type="button"
    class="btn btn-primary my-2"
    on:click|preventDefault={handleClick}>Synthesize</button
  >
</form>

{#if ttsArray}
  <ul class="list-group my-2">
    {#each ttsArray as tts}
      <li class="list-group-item">
        <!-- svelte-ignore a11y-media-has-caption -->
        <audio
          controls
          autoplay={ttsArray && ttsArray.length === 1}
          src={tts.url}
          type="audio/mp3"
          title={tts.name}>{tts.text}</audio
        >
      </li>
    {/each}
  </ul>

  <button
    type="button"
    class="btn btn-secondary my-2"
    on:click|preventDefault={downloadAllTtsFiles(ttsArray)}>Download All</button
  >
{/if}
