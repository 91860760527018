<script lang="ts">
  import { Link, useLocation } from "svelte-navigator";
  import { doSignOut } from "../firebase";
  import type { User } from "firebase/auth";
  import { menuNavbar } from "./menu";
  import { appName } from "../constants";

  export let user: User;

  const location = useLocation();
</script>

<nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
  <div class="container-fluid">
    <Link class="navbar-brand" to="">{appName}</Link>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon" />
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        {#each menuNavbar as menu}
          <li class="nav-item">
            <Link
              class="nav-link {menu.enable && menu.to === $location.pathname
                ? 'active'
                : ''} {menu.enable ? '' : 'disabled'}"
              to={menu.to}>{menu.label}</Link
            >
          </li>
        {/each}
      </ul>
      <ul class="navbar-nav navbar-right">
        <li>
          <Link class="nav-link" to="account">{user.displayName}</Link>
        </li>
        <li>
          <button type="button" class="btn btn-primary" on:click={doSignOut}
            >Sign Out</button
          >
        </li>
      </ul>
    </div>
  </div>
</nav>
